import React from 'react';

const MultiplePdfViewer = ({ ids }) => (
  <div>
    {ids.map((id) => (
      <div key={id} style={{ marginBottom: '20px' }}>
        <h2>PDF ID: {id}</h2>
        <object
          data={`/${id}.pdf`}
          type="application/pdf"
          width="100%"
          height="500px"
        >
          <p>It appears you don't have a PDF plugin for this browser. No biggie... you can <a href={`/${id}.pdf`}>click here to download the PDF file.</a></p>
        </object>
      </div>
    ))}
  </div>
);

export default MultiplePdfViewer;